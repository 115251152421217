import { useEffect, useRef, useState } from "react";
import "./Splash.css"; // Ensure you have this CSS file
import Sky from "./Sky";
import StyledButton from "../_Global/StyledButton";
const moon = require('./moon.png')
const sun = require('./sun.png')

const Splash = () => {
    const [splashTextWord, setSplashTextWord] = useState<string>("");
    const [timeOfDay, setTimeOfDay] = useState<string>("night")
    const splashStatement: string = "3 2 1 Let's go!";
    const wordChangeTimer: number = 1.25;
    const fadeOutInterval: number = 25
    const fadeOutAmount: number = 0.02
    const currentFadeOutAmount = useRef<number>(1)
    const phase = useRef<number>(-1)
    const showNext = useRef<boolean>(false)
    const phaseWords:any = [
        "I'm sure you're not here to see clouds and stars",
        "But since we are talking about UI. . .",
        "I guess we should start there!",
        "All you see here today was created with ReactJS",
        "as well as Typescript.",
        "And yes, these are functional components.",
        "The stars and clouds are randomly generated",
        "and move asynchronously.",
        "Let's change up the scene a bit, shall we?",
        "That's better. It's nice to change things up.",
        "Ok. So we have a blank canvas. Let's do something.",
        "",
        "As you can see, I understand API's.",
        "Uh oh. 'API'. . .that's back-end talk.",
        "Looks like we've moved on to server-side.",
        "Personally, I enjoy PHP and frameworks such as Symfony.",
        "In fact. . .",
        "I am currently the Senior Full Stack Engineer",
        "for a national construction company.",
        "My team utilizes technologies such as",
        "ReactJS, Typescript, PHP, Symfony, and My/MSSQL",
        "every day to develop cutting edge PWAs.",
        "That purple was a little too bright.",
        "Sorry!",
        "In addition to the stack I use daily,",
        "I have also used C# and Java.",
        "Though, I may be a little rusty.",
        "Let's peel back a layer or two. . .",

    ]
    const [doSomethingLabel, setDoSomethingLabel] = useState<string>("DO SOMETHING")
    //Phase Triggers
    const letsDoSomething = useRef<boolean>(false)
    useEffect(() => {
        fadeInSplashText();
        document.body.style.overflow = "hidden"
    }, []);

    async function fadeInSplashText() {
        const wordArray = splashStatement.split(" ");
        setSplashTextWord(wordArray[0])
        let currentWord: number = 0;
        const wordContainer = document.getElementById("splashText") as HTMLElement;

        if (wordContainer) {
            const wordChangeInterval = setInterval(() => {
                if (currentWord === wordArray.length) {
                    clearInterval(wordChangeInterval);
                    fadeOutSplash()
                } else {
                    // Fade out
                    wordContainer.classList.remove('show');
                    setTimeout(() => {
                        setSplashTextWord(wordArray[currentWord]);
                        wordContainer.classList.add('show'); // Fade in
                    }, 500); // Match the transition duration
                    currentWord++;
                }
            }, wordChangeTimer * 1000);
        }
    }

    /**
     * The `fadeOutSplash` function asynchronously fades out a splash element on a webpage by reducing
     * its opacity over time.
     */
    async function fadeOutSplash(){
        setTimeOfDay("day")
        const splash = document.getElementById("splash") as HTMLElement
        const moonImage = document.getElementById("moon") as HTMLElement
        const sunImage = document.getElementById("sun") as HTMLElement
        if ( splash && moonImage && sunImage ){
            const fadeOut = setInterval(function(){
                if ( currentFadeOutAmount.current === 0 ){
                    clearInterval(fadeOut)                    
                }
                else{
                    currentFadeOutAmount.current = currentFadeOutAmount.current - fadeOutAmount
                    splash.classList.add("daytime")
                    moonImage.style.opacity = currentFadeOutAmount.current.toString()
                    sunImage.classList.add('in');
                }
            }, fadeOutInterval)
        }
    }

    /**
     * The `sunDrag` function adds a CSS class to a specific element and sets a text content to "Please
     * don't steal my sunlight".
     */
    function sunDrag(){
        const wordContainer = document.getElementById("splashText") as HTMLElement;
        wordContainer.classList.add('show');
        setSplashTextWord("Please don't steal my sunlight :)")
    }

    /**
     * The function `moonDrag` adds a CSS class to an element with the ID "splashText" and sets its
     * text content to "Ok, ok. I'll make it daytime".
     */
    function moonDrag(){

    }

    function daytime(returnValue:string){
        showNext.current = true
        phase.current++
        if ( returnValue === "day" ){
            setSplashTextWord("Welcome to my portfolio")
            const splashText = document.getElementById("splashText") as HTMLElement
            if ( splashText ){
                splashText.classList.add("black-text")
            }
        }
    }

    /**
     * The function `next` removes a CSS class from an element, sets a new text content, adds a CSS
     * class to fade in the element, increments a counter, and clears a timeout.
     */
    function next(){
        console.log(phase.current)
        const wordContainer = document.getElementById("splashText") as HTMLElement;
        const sun = document.getElementById("sun") as HTMLElement;
        const splash = document.getElementById("splash") as HTMLElement;
        let swapTimeout:number = 500
        if ( phase.current === 9 ){
            //First scene swap
            swapTimeout = 1500
            wordContainer.classList.remove('show');
            wordContainer.classList.add('hide');
            const clouds = document.getElementsByClassName("cloud")
            if ( clouds && sun && splash ){
                sun.classList.remove("in")
                sun.classList.add("out")
                splash.classList.remove("daytime")
                splash.classList.add("phase9")
                for ( let i = 0; i < clouds.length; i++ ){
                    clouds[i].classList.add("fade")
                }
                wordContainer.classList.remove("black-text")
                wordContainer.classList.add("white-text")
            }
        }
        if ( phase.current === 15 ){
            //Back-End Swap
            if ( splash ){
                splash.classList.remove("phase9")
                splash.classList.add("phase16")
            }
        }
        if ( phase.current === 22 ){
            //PHP out Swap
            if ( splash ){
                splash.classList.remove("phase16")
                splash.classList.add("phase22")
            }
        }
        if ( phase.current === 28 ){
            //Layers swap
            if ( splash ){
                splash.classList.remove("phase22")
                splash.classList.add("phase28")
                showNext.current = false
                document.body.style.overflowY = "auto"
            }
        }
        if ( phase.current === 10 ){
            //Let's Do something
            letsDoSomething.current = true
            showNext.current = false
        }
        const currentPhase = phase.current
        if ( wordContainer.classList.contains("show") ){
            wordContainer.classList.remove('show');
        }
        setTimeout(() => {
            if ( wordContainer.classList.contains("hide") ){
                wordContainer.classList.remove("hide")
            }
            wordContainer.classList.add('show'); // Fade in
            setSplashTextWord(phaseWords[currentPhase]);
        }, swapTimeout); // Match the transition duration

        //Next phase queue up
        phase.current++
    }

    async function getCurrentTemperatureAndCity(): Promise<{ temperature: number | null, city: string | null }> {
        if (!navigator.geolocation) {
            console.error("Geolocation is not supported by this browser.");
            return { temperature: null, city: null };
        }
    
        return new Promise<{ temperature: number | null, city: string | null }>((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(
                async (position) => {
                    const { latitude, longitude } = position.coords;
    
                    try {
                        // Get weather data
                        const weatherResponse = await fetch(
                            `https://api.open-meteo.com/v1/forecast?latitude=${latitude}&longitude=${longitude}&current_weather=true`
                        );
    
                        if (!weatherResponse.ok) {
                            throw new Error("Weather request failed");
                        }
    
                        const weatherData = await weatherResponse.json();
                        const temperatureC = weatherData.current_weather.temperature; // Temperature in Celsius
                        const temperatureF = (temperatureC * 9 / 5) + 32; // Convert to Fahrenheit
    
                        // Get city name using reverse geocoding
                        const geoResponse = await fetch(
                            `https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json`
                        );
    
                        if (!geoResponse.ok) {
                            throw new Error("Geocoding request failed");
                        }
    
                        const geoData = await geoResponse.json();
                        const city = geoData.address?.city || geoData.address?.town || geoData.address?.village || null;
    
                        resolve({ temperature: temperatureF, city });
                    } catch (error) {
                        console.error("Error fetching the data:", error);
                        resolve({ temperature: null, city: null });
                    }
                },
                (error) => {
                    console.error("Error getting location:", error);
                    reject(error);
                }
            );
        });
    }

    //Phase Triggers
        //Phase 11
        function phase11(){
            letsDoSomething.current = false
            setSplashTextWord("Pardon me, but could you kindly hit the allow button?")
            setTimeout(function(){
                getCurrentTemperatureAndCity().then(({temperature, city}) => {
                    if ( temperature !== null ){ 
                        if ( Number(temperature) >= 75 ){
                            setSplashTextWord("It's " + temperature.toString().substring(0, 2) + "°F in " + city + " - That's warm!")
                        }
                        if ( Number(temperature) >= 60 && Number(temperature) < 75 ){
                            setSplashTextWord("It's " + temperature.toString().substring(0, 2) + "°F in " + city + " - Nice and comfy!")
                        }
                        if ( Number(temperature) < 60 ){
                            setSplashTextWord("It's " + temperature.toString().substring(0, 2) + "°F in " + city + " - You might need a jacket!")
                        }
                    }
                    else{
                        setSplashTextWord("The API didn't respond :( So..maybe it's 70°F?")
                    }
                    phase.current++
                    showNext.current = true
                })
            }, 1000)
        }

    return (
        <>
            <Sky tod={timeOfDay} callback={daytime} />
            <div id="splash" className="row splash">
                <div id="moon" onDragStart={moonDrag} className="moon"><img style={{width: "100%"}} src={moon} /></div>
                <div onDragStart={sunDrag} id="sun" className="sun"><img style={{width: "100%"}} src={sun} /></div>
            </div>
            <div id="splashText" className="col s12 center splashText show">{splashTextWord}</div>
            { showNext.current ?
                <StyledButton id="main" label="Next" action={next} />
            :
                <></>
            }
            { letsDoSomething.current ? 
                <a className="splashlink" onClick={phase11}>{doSomethingLabel}</a>
                :
                <></>
            }
        </>
    );
};

export default Splash;
